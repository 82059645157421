<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18 3.01855C11.9249 3.01855 7 7.94342 7 14.0186C7 16.6961 7.95662 19.1501 9.54673 21.0576L3.29289 27.3114C2.90237 27.702 2.90237 28.3351 3.29289 28.7257C3.68342 29.1162 4.31658 29.1162 4.70711 28.7257L10.9609 22.4718C12.8684 24.0619 15.3225 25.0186 18 25.0186C24.0751 25.0186 29 20.0937 29 14.0186C29 7.94342 24.0751 3.01855 18 3.01855ZM9 14.0186C9 9.04799 13.0294 5.01855 18 5.01855C22.9706 5.01855 27 9.04799 27 14.0186C27 18.9891 22.9706 23.0186 18 23.0186C13.0294 23.0186 9 18.9891 9 14.0186Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconSearch",
});
</script>
